<AppLoader v-if="isLoading"></AppLoader>
<div class="dashboard-right-inner">
  <div class="">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div
        v-if="campaignsList?.items?.length > 0 || searchText || campaignValue?.id !=0 || campaignTypeValue?.id !=0 || filterByDateValue?.id!=0 "
        class="d-flex flex-wrap justify-content-between pb-0"
      >
        <div class="d-flex flex-wrap align-items-start gap-2">
          <div class="tab-with-button-wrap d-flex mb-9">
            <kbutton
              :class="{ active: !isArchived }"
              @click="toggleCampaign(false)"
              >All Campaign</kbutton
            >
            <kbutton
              :class="{ active: isArchived }"
              @click="toggleCampaign(true)"
              >Archived Campaign</kbutton
            >
          </div>
          <div
            class="search-box-wrap width-220 w-100-vsm mb-9 me-0 me-sm-3 me-md-4"
          >
            <span class="k-icon k-i-zoom"></span>
            <k-input
              :class="'mb-0'"
              placeholder="Search"
              v-model="searchQuery"
              @input="debounceSearch"
            >
            </k-input>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-3">
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Status</label
              >
              <dropdownlist
                :class="'w-100-vsm'"
                :style="{ width: '140px' }"
                :data-items="campaignStatusTemplates"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="campaignValue"
                @change="handleCampaignStatusChange"
              >
              </dropdownlist>
            </span>
          </div>

          <div class="w-100-vsm">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Date</label
              >
              <dropdownlist
                :class="'w-100-vsm'"
                :style="{ width: '140px' }"
                :data-items="filterByDateType"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="filterByDateValue"
                @change="handleFilterByDateChange"
              >
              </dropdownlist>
            </span>
          </div>
          <div class="w-100-vsm" v-if="filterByDateValue?.id===4">
            <AppDateRangePicker
              @dateSelected="handleDateSelected"
              @error="handleError"
              :class="'w-100-vsm'"
              :style="{ width: '210px' }"
            >
            </AppDateRangePicker>
          </div>
        </div>
      </div>
    </div>

    <!--dashboard-content-->
    <div class="dashboard-content py-0 px-0">
      <grid
        :class="'list-analytics-campaigns-table table-design-2 pager-fixed-with-table 2xl:h-[48.813rem] lg:h-[37.75rem]'"
        :data-items="campaignsList?.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        @datastatechange="dataStateChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
        :columns="allCmpaignColumns"
      >
        <template v-slot:nameCell="{ props }">
          <td>
            <span
              :disabled="this.hasEditAccess() == false"
              @click="doNavigateToViewCampaign(props.dataItem)"
              class="font-14 font-w-500 color-blue cursor-pointer"
              >{{cardNameFormat(props.dataItem.campaignName)}}</span
            >
            <div
              class="campaign-transaction d-flex flex-wrap justify-content-start align-items-center"
            >
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot purple radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Sent: {{props.dataItem?.uniqueSent}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot green radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Delivered: {{props.dataItem?.uniqueDelivered}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot red radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Bounced: {{props.dataItem?.uniqueBounced}}
                </div>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:typesOfDeliveryCell="{ props }">
          <td>
            <div class="d-flex align-items-center gap-2">
              <span v-if="props.dataItem.distributionChannel !== Email">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6673 1.99984C14.6673 1.2665 14.0673 0.666504 13.334 0.666504H2.66732C1.93398 0.666504 1.33398 1.2665 1.33398 1.99984M14.6673 1.99984V9.99984C14.6673 10.7332 14.0673 11.3332 13.334 11.3332H2.66732C1.93398 11.3332 1.33398 10.7332 1.33398 9.99984V1.99984M14.6673 1.99984L8.00065 6.6665L1.33398 1.99984"
                    stroke="#ADB2BD"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span v-if="props.dataItem.distributionChannel == Email">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z"
                    stroke="#ADB2BD"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="font-12 font-w-500"
                >{{props?.dataItem?.distributionChannel == 1 ?
                "Email":"SMS"}}</span
              >
            </div>
          </td>
        </template>

        <template v-slot:statusCell="{ props }">
          <td>
            <span
              :class="['campaign-status styled-2 font-12 font-w-600 radius-100 py-1 px-8 text-center',campaignStatusColor[props?.dataItem?.campaignStatus]]"
            >
              {{campaignStatus[props?.dataItem?.campaignStatus]}}
            </span>
          </td>
        </template>

        <template v-slot:createdAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.createdAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.createdAt)}}
            </p>
          </td>
        </template>
        <template v-slot:LastUpdateAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.updatedAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.updatedAt)}}
            </p>
          </td>
        </template>

        <template v-slot:openRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.uniqueOpened,props.dataItem?.uniqueSent) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.uniqueOpened,props.dataItem?.uniqueDelivered)||
              0}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.uniqueSent||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:bounceRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalBounced,props.dataItem?.totalSent) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.totalBounced,props.dataItem?.totalSent)||
              0}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.totalSent||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:conversionRateCell="{ props }">
          <td>
            <h3
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalConversion,props.dataItem?.totalDelivered) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.totalConversion,props.dataItem?.uniqueDelivered)||
              0}}%
            </h3>
            <p class="font-12 m-0">
              ${{parseFloat((props.dataItem?.totalConversionValue ||
              0).toFixed(2))}} Conv. value
            </p>
          </td>
        </template>
        <template v-slot:actionCell="{ props }">
          <td>
            <AppPopUp
              :voucher="props.dataItem"
              :items="generateCampaignActions(props.dataItem?.campaignStatus,props?.dataItem)"
              @dropId="getDropId"
              :icon="'more-vertical'"
              :popUpSettings="popupSettings"
              :buttonClass="'btn-clear'"
            >
            </AppPopUp>
          </td>
        </template>
        <GridNoRecords>
          No campaigns available. Archived campaigns or campaigns matching your
          
          search criteria will appear here when found.
        </GridNoRecords>
      </grid>
    </div>
  </div>
</div>

<WarningModal
  v-if="deleteCampaignModalVisibility"
  :modalDetails="deleteCampaignModalDetails"
  :onCancel="onCancelDeleteCampaignModal"
  :onConfirm="onConfirmDeleteCampaignModal"
/>

<WarningModal
  v-if="pauseCamaignWarningModalVisibility"
  :modalDetails="pauseCampaignWarningModalDetails"
  :onCancel="onCancelPauseCampaign"
  :onConfirm="onConfirmPauseCampaign"
/>

<WarningModal
  v-if="resumeCampaignWarningModalVisibility"
  :modalDetails="resumeCampaignWarningModalDetails"
  :onCancel="onCancelResumeCampaign"
  :onConfirm="onConfirmResumeCampaign"
/>

<RescheduleCampaignModal
  :prePayload="selectedCampaign"
  :onCancel="onCancelRescheduleCampaign"
  :onConfirm="onConfirmRescheduleCampaign"
  v-if="RescheduleCampaignModalVisibility"
/>
