<template src="./listAndSegmentAnalytics.html" lang=""></template>
<style lang="scss">
@import "./listAndSegmentAnalytics.scss";
</style>

<script>
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartSeriesItemTooltip,
  ChartLegend,
} from "@progress/kendo-vue-charts";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import appLineChart from "@/components/common/AppLineChart/appLineChart.vue";
import { ProgressBar } from "@progress/kendo-vue-progressbars";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Button } from "@progress/kendo-vue-buttons";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { filterBy } from "@progress/kendo-data-query";
import "hammerjs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import clientsAndDevices from "@/components/svg-icons/clientsAndDevices.vue";
import performanceByLocation from "@/components/svg-icons/performanceByLocation.vue";
import performanceBenchmarksEmpty from "@/components/svg-icons/performanceBenchmarksEmpty.vue";
import ListAndSegmentAllCampaign from "@/components/clubDetails/audience/common/listAndSegmentAllCampaign/AllCampaign.vue";
import { getAllAudienceColumnService } from "@/services/clubDetails/listSegmentReport/listSegmentReport.service";
import AppAreaChart from "@/components/common/AppAreaChart/AppAreaChart.vue";
import AppDateRangePicker from "@/components/common/AppDateRangePicker/AppDateRangePicker.vue";

export default {
  name: "ListAndSegmentAnalytics",
  components: {
    Grid: Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    ChartSeriesItemTooltip,
    ChartLegend,
    appLineChart,
    AppLoader,
    ProgressBar,
    dropdownlist: DropDownList,
    kbutton: Button,
    custom: NoDataRender,
    datePicker: DatePicker,
    clientsAndDevices,
    performanceByLocation,
    performanceBenchmarksEmpty,
    ListAndSegmentAllCampaign,
    AppAreaChart,
    AppDateRangePicker,
  },
  data: function () {
    return {
      deviceFilterObject: {
        clientAndDevices: [
          {
            name: "Overall",
            value: "overall",
            checked: true,
            id: 1,
          },
          {
            name: "Mobile",
            value: "mobile",
            checked: false,
            id: 2,
          },
          {
            name: "Desktop",
            value: "desktop",
            checked: false,
            id: 3,
          },
        ],
        performanceBenchmarks: [
          {
            name: "Click Rate",
            value: "clickRate",
            checked: true,
            id: 1,
          },
          {
            name: "Open Rate",
            value: "openRate",
            checked: false,
            id: 2,
          },
          {
            name: "Converted",
            value: "converted",
            checked: false,
            id: 3,
          },
        ],
        performanceByVariations: [
          {
            name: "Overall",
            value: "overall",
            checked: true,
            id: 1,
          },
          {
            name: "Mobile",
            value: "mobile",
            checked: false,
            id: 2,
          },
          {
            name: "Desktop",
            value: "desktop",
            checked: false,
            id: 3,
          },
        ],
      },
      currentTimeZone: null,
      listNoDataRender: "noDataFoundTemplate",
      overallPerformanceHtmlTreeObject: [
        {
          title: "Open Rate",
          color: "color-1",
          id: 1,
        },
        {
          title: "Click Rate",
          color: "color-2",
          id: 2,
        },
        {
          title: "Conversion Rate",
          color: "color-4",
          id: 3,
        },
        {
          title: "Total Conversion",
          color: "color-3",
          id: 4,
        },
        {
          title: "Unsubscribe Rate",
          color: "color-3",
          id: 5,
        },
      ],
      performanceOverTimeHtmlTreeObject: [
        {
          title: "SENT",
          color: "color-1",
          id: 1,
        },
        {
          title: "UNIQUE OPENS",
          color: "color-2",
          id: 2,
        },
        {
          title: "UNIQUE CLICKS",
          color: "color-4",
          id: 3,
        },
        {
          title: "TOTAL VALUE",
          color: "color-3",
          id: 4,
        },
        {
          title: "BOUNCED",
          color: "color-5",
          id: 5,
        },
      ],
      selectedDropdownvalue: {
        id: 1,
        text: "Last 24 Hours",
        message: "Showing performance for last 24 hours",
      },
      selectedVariation: { id: null, variationNo: "All Variation" },
      variationsData: [],
      isColumnLoading: false,
      checkedColumns: [],
      allColumns: [],
      audiencePrimarySourceData: [],

      segmentId: this.$route.params.segmentId,
      listId: this.$route.params.listId,

      startDate: "",
      endDate: "",
      iscampaignListVisible: true,
    };
  },

  props: {
    campaignDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.setDates();
  },

  mounted() {
    this.segmentId = this.$route.params.segmentId;
    this.listId = this.$route.params.listId;

    this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getColumns();

    const payload = {
      clubId: this.$route.params.id,
      analyticsType: this.segmentId ? 1 : 0,
      ...(this.segmentId && {
        segmentId: this.segmentId,
      }),
      ...(this.listId && {
        listId: this.listId,
      }),
    };
    // this.getEmailCampaigns()

    this.$store.dispatch("getAudienceVariations", {
      clubId: this.$route.params.id,
      analyticsType: this.segmentId ? 1 : 0,
      ...(this.segmentId && {
        segmentId: this.segmentId,
      }),
      ...(this.listId && {
        listId: this.listId,
      }),
      fromDate: this.startDate,
      toDate: this.endDate,
      zone: this.currentTimeZone,
    });

    this.$store.dispatch("getAudienceClientDevices", payload);
    this.$store.dispatch("getAudiencePerformanceByLocation", payload);
    this.$store.dispatch("getAudiencePerformanceBenchmark", payload);
    this.$store.dispatch("getAudienceOverallPerformance", payload);
    this.$store.dispatch("getCampaignPerformanceOverTime", {
      campaignId: this.$route.params.campaignId,
      fromDate: this.startDate,
      toDate: this.endDate,
      zone: this.currentTimeZone,
      clubId: this.$route.params.id,
    });
  },

  beforeUnmount() {
    this.$store.commit("clearListSegmentReportData");
  },


  computed: {
    columnForClientAndDevicesTypeMobile() {
      return [
        {
          field: "category",
          title: `Mobile (${this.audienceClientDevicesData?.totalMobile})%`,
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    subscriberSourceColumns() {
      return [
        {
          field: "PrimarySource",
          title: "Source",
        },
        ...this.checkedColumns,
      ];
    },
    columnForClientAndDevicesTypeDesktop() {
      return [
        {
          field: "category",
          title: `Desktop (${this.audienceClientDevicesData?.totalDesktop})%`,
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    columnForPerformanceByLocations() {
      return [
        {
          field: "category",
          title: "Countries",
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    columnForPerformanceBenchmark() {
      return [
        {
          field: "name",
          title: "Campaign Name",
        },
        {
          field: "TotalAudience",
          title: "Audience",
        },
        {
          field: "clickRate",
          title: "Click Rate",
          cell: "clickRateCell",
        },
        {
          field: "openRate",
          title: "Open Rate",
          cell: "openRateCell",
        },
        {
          field: "bounceRate",
          title: "Bounce",
          cell: "bounceRateCell",
        },
      ];
    },
    audienceVariationsData() {
      return this.$store.state.listSegmentReport.audienceVariationsData;
    },
    audienceVariationsLoader() {
      return this.$store.state.listSegmentReport.audienceVariationsLoader;
    },
    audienceOverallPerformanceData() {
      return this.$store.state.listSegmentReport.audienceOverallPerformanceData;
    },
    audienceOverallPerformanceLoader() {
      return this.$store.state.listSegmentReport
        .audienceOverallPerformanceLoader;
    },
    audienceClientDevicesData() {
      return this.$store.state.listSegmentReport.audienceClientDevicesData;
    },
    audienceClientDevicesLoader() {
      return this.$store.state.listSegmentReport.audienceClientDevicesLoader;
    },
    audiencePerformanceByLocationData() {
      return this.$store.state.listSegmentReport
        .audiencePerformanceByLocationData;
    },
    audiencePerformanceByLocationLoader() {
      return this.$store.state.listSegmentReport
        .audiencePerformanceByLocationLoader;
    },
    audiencePerformanceBenchmarkData() {
      return this.$store.state.listSegmentReport
        .audiencePerformanceBenchmarkData;
    },
    audiencePerformanceBenchmarkLoader() {
      return this.$store.state.listSegmentReport
        .audiencePerformanceBenchmarkLoader;
    },
  },
  methods: {
    campignListVisibilityHandler(value) {
      this.iscampaignListVisible = value;
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        columns.push({
          field: element.field,
          title: element.title,
        });
      });
      return columns;
    },
    getColumns() {
      this.isColumnLoading = true;
      getAllAudienceColumnService({
        clubId: this.$route.params.id,
        zone: this.currentTimeZone,
        // AnalyticsType: 0,
        ...(this.segmentId && {
          segmentId: this.segmentId,
        }),
        ...(this.listId && {
          listId: this.listId,
        }),
      })
        .then((res) => {
          this.isColumnLoading = false;
          this.allColumns = res.data.data.subscriberSourceColumn;
          this.audiencePrimarySourceData = res.data.data.audiencePrimarySource;
          this.checkedColumns = this.culumnGenerator(
            res.data.data.subscriberSourceColumn
          );
        })
        .catch((err) => {
          this.isColumnLoading = false;
        });
    },
    setDates() {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
      this.endDate = today.toISOString();
      this.startDate = thirtyDaysAgo.toISOString();
    },
    handleDateSelected({ start, end }) {
      this.startDate = start != null ? new Date(start) : null;
      this.endDate = end != null ? new Date(end) : null;
      if (!this.startDate && !this.endDate) {
        this.setDates();
      }
      this.$store.dispatch("getAudienceVariations", {
        clubId: this.$route.params.id,
        analyticsType: this.segmentId ? 1 : 0,
        listId: this.$route.params.listId,
        segmentId: this.segmentId,
        fromDate: this.startDate,
        toDate: this.endDate,
        zone: this.currentTimeZone,
      });
      this.$store.dispatch("getCampaignPerformanceOverTime", {
        campaignId: this.$route.params.campaignId,
        fromDate: this.startDate,
        toDate: this.endDate,
        zone: this.currentTimeZone,
        clubId: this.$route.params.id,
      });
    },

    valuePerSendCalc(value1, value2) {
      if (!value1 || !value2) return 0;
      return (value1 / value2).toFixed(2);
    },
    toggleFilterDeviceHandler(type, index) {
      for (let i = 0; i < this.deviceFilterObject[type].length; i++) {
        this.deviceFilterObject[type][i].checked = false;
      }
      this.deviceFilterObject[type][index].checked = true;

      if (type == "clientAndDevices") {
        this.audienceClientDevicesData.selected =
          this.audienceClientDevicesData[
            this.deviceFilterObject[type][index]?.value
          ];
      }

      if (type == "performanceBenchmarks") {
        this.audiencePerformanceBenchmarkData.map((item) => {
          item.dataValue = [item[this.deviceFilterObject[type][index]?.value]];
        });
      }
    },
    updateStartDate(event) {
      this.startDate = event.target.value;
      this.reFetchPerformanceOverTimeData();
    },
    updateEndDate(event) {
      this.endDate = event.target.value;
      this.reFetchPerformanceOverTimeData();
    },
    percentageCalculator(value, total) {
      if (!value || !total) return 0;
      return parseFloat(((value / total) * 100).toFixed(2));
    },
    subTractMinutes(date, minutes) {
      return new Date(date.getTime() - minutes * 60000);
    },
    subtractDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    },
    subtractMonths(date, months) {
      let result = new Date(date);
      result.setMonth(result.getMonth() - months);
      return result;
    },
    reFetchPerformanceOverTimeData() {
      let fromDate = null;
      let dateObject;
      let toDate = new Date();

      if (this.selectedDropdownvalue?.id == 1) {
        fromDate = this.subtractDays(new Date(), 1);
      }

      if (this.selectedDropdownvalue?.id == 2) {
        fromDate = this.subtractDays(new Date(), 7);
      }

      if (this.selectedDropdownvalue?.id == 3) {
        fromDate = this.subtractMonths(new Date(), 1);
      }

      if (this.selectedDropdownvalue?.id == 4) {
        fromDate = this.subtractMonths(new Date(), 3);
      }

      if (this.selectedDropdownvalue?.id == 5) {
        if (this.startDate && this.endDate) {
          // add current utc time to from date and to date.
          fromDate = this.startDate;
          toDate = this.endDate;

          if (this.endDate.toDateString() == new Date().toDateString()) {
            let result = new Date(this.endDate);
            result.setDate(result.getDate() + 1);
            toDate = this.subTractMinutes(result, 1);
          }
        } else {
          return;
        }
      }
      this.$store.dispatch("getAudienceVariations", {
        clubId: this.$route.params.id,
        analyticsType: this.segmentId ? 1 : 0,
        ...(this.segmentId && {
          segmentId: this.segmentId,
        }),
        ...(this.listId && {
          listId: this.listId,
        }),
        fromDate: fromDate,
        toDate: toDate,
        zone: this.currentTimeZone,
      });
    },
    onVariationDropdownOpen() {
      this.variationsData = structuredClone(this.audienceVariationsData);
    },
    variationFilterChange(event) {
      this.variationsData = this.variationFilterData(event.filter);
    },
    variationFilterData(filter) {
      const data = this.audienceVariationsData.slice();
      return filterBy(data, filter);
    },
    handledropdownSelection(event) {
      this.selectedDropdownvalue = event.value;
      if (this.selectedDropdownvalue.id == 5) {
        this.startDate = null;
        this.endDate = null;
      }
      this.reFetchPerformanceOverTimeData();
    },
    handleVariationChange(event) {
      this.selectedVariation = event.value;
      this.reFetchPerformanceOverTimeData();
    },
    // percentageCalc(a, b) {
    //   return Math.ceil((a / b) * 100);
    // },
    percentageCalc(value, total) {
      if (!value || !total) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return parseFloat(((value / total) * 100).toFixed(2));
        } else {
          return 0;
        }
      }
    },
    growthRate(beforeTotalAudience, afterTotalAudience) {
      console.log(beforeTotalAudience, afterTotalAudience, "c78");

      if (!beforeTotalAudience && !afterTotalAudience) {
        return 0;
      } else if (beforeTotalAudience === afterTotalAudience) {
        return 0;
      } else {
        return Math.min(
          parseFloat(
            (
              ((afterTotalAudience - beforeTotalAudience) /
                Math.max(beforeTotalAudience, 1)) *
              100
            ).toFixed(2)
          ),
          100
        );
      }
    },
  },
};
</script>
