<template>
  <Chart>
    <ChartTitle :text="''" />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem
        :categories="categories"
        :title="{
          text: '',
        }"
      />
    </ChartCategoryAxis>
    <ChartSeries>
      <ChartSeriesItem :type="'area'" :data-items="categoryData" />
    </ChartSeries>
  </Chart>
</template>
<script>
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-vue-charts";

export default {
  components: {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
  },
  props: {
    lineGraphData: {
      type: Array,
      required: true,
    },
  },
  created() {},
  mounted() {
    this.updateGraphData();
  },
  watch: {
    lineGraphData: {
      handler() {
        this.updateGraphData();
      },
      deep: true,
    },
  },
  data: function () {
    return {
      categoryData: [],
      categories: [],
    };
  },
  methods: {
    updateGraphData() {
      this.categoryData = this.lineGraphData?.map((item) => item.yAxis) || [];
      this.categories = this.lineGraphData?.map((item) => item.xAxis) || [];
    },
  },
};
</script>
