<template>
    <svg width="216" height="184" viewBox="0 0 216 184" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="107.5" cy="92" r="92" fill="#253858"/>
    <g filter="url(#filter0_bdd_5450_102624)">
    <rect x="31.2822" y="53.0176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter1_bd_5450_102624)">
    <rect x="27.5996" y="43.9238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter2_dd_5450_102624)">
    <rect x="22.5" y="33" width="170.842" height="108.97" rx="3.69388" fill="white"/>
    </g>
    <g filter="url(#filter3_d_5450_102624)">
    <path d="M76.5 91.25V49.5C76.5 48.3954 77.3954 47.5 78.5 47.5H128.79L139.5 58.75V130.5C139.5 131.605 138.605 132.5 137.5 132.5H78.5C77.3954 132.5 76.5 131.605 76.5 130.5V91.25Z" fill="#F8F9FA"/>
    <path d="M128.79 47.5H78.5C77.3954 47.5 76.5 48.3954 76.5 49.5V91.25V130.5C76.5 131.605 77.3954 132.5 78.5 132.5H137.5C138.605 132.5 139.5 131.605 139.5 130.5V58.75M128.79 47.5L139.5 58.75M128.79 47.5V56.75C128.79 57.8546 129.685 58.75 130.79 58.75H139.5" stroke="#A4C3F3" stroke-linecap="round"/>
    </g>
    <path d="M86.5 66.3696C86.5 65.337 87.337 64.5 88.3696 64.5H93.9783C95.0108 64.5 95.8478 65.337 95.8478 66.3696V114.355H86.5V66.3696Z" fill="#007BFF" fill-opacity="0.2"/>
    <path d="M103.326 85.0649C103.326 84.0323 104.163 83.1953 105.196 83.1953H110.804C111.837 83.1953 112.674 84.0323 112.674 85.0649V114.355H103.326V85.0649Z" fill="#0052CC" fill-opacity="0.5"/>
    <path d="M120.152 72.601C120.152 71.5685 120.989 70.7314 122.022 70.7314H127.631C128.663 70.7314 129.5 71.5685 129.5 72.601V114.355H120.152V72.601Z" fill="#0052CC" fill-opacity="0.5"/>
    <defs>
    <filter id="filter0_bdd_5450_102624" x="23.2431" y="44.9784" width="170.641" height="114.361" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5450_102624"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.81524"/>
    <feGaussianBlur stdDeviation="1.81524"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_5450_102624" result="effect2_dropShadow_5450_102624"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4.01957"/>
    <feGaussianBlur stdDeviation="2.00978"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
    <feBlend mode="normal" in2="effect2_dropShadow_5450_102624" result="effect3_dropShadow_5450_102624"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_5450_102624" result="shape"/>
    </filter>
    <filter id="filter1_bd_5450_102624" x="19.8047" y="36.1289" width="177.518" height="118.556" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5450_102624"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.76009"/>
    <feGaussianBlur stdDeviation="1.76009"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_5450_102624" result="effect2_dropShadow_5450_102624"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5450_102624" result="shape"/>
    </filter>
    <filter id="filter2_dd_5450_102624" x="0.3367" y="18.2245" width="215.168" height="153.296" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7.38777"/>
    <feGaussianBlur stdDeviation="11.0816"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5450_102624"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.84694"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_5450_102624" result="effect2_dropShadow_5450_102624"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5450_102624" result="shape"/>
    </filter>
    <filter id="filter3_d_5450_102624" x="72.3061" y="41.1561" width="71.3878" height="93.3878" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-2.15"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5450_102624"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5450_102624" result="shape"/>
    </filter>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "performanceBenchmarksEmpty"
    }
</script>
<style scoped></style>