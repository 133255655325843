<app-loader
  v-if="audiencePerformanceBenchmarkLoader || audienceOverallPerformanceLoader || audienceClientDevicesLoader || audiencePerformanceByLocationLoader || audienceVariationsLoader ||isMenuLoading"
></app-loader>
<div class="p-24">
  <div class="advanced-reports-wrap">
    <div>
      <div v-if="audienceOverallPerformanceData">
        <div class="premium-analytics-all d-flex justify-content-between mb-20">
          <div class="premium-analytics-card bg-white radius-10 d-flex">
            <div class="content w-100">
              <p class="analytics-status text-color-5 font-15 font-w-500 mb-1">
                Open Rate
              </p>
              <p
                class="info d-flex align-items-center font-14 line-h-16 font-w-500 mb-0 text-color-2"
              >
                <b class="font-16 l-height-24 font-w-600 base-color"
                  >{{percentageCalc(audienceOverallPerformanceData.uniqueOpened,audienceOverallPerformanceData.uniqueDelivered)
                  || 0}}%</b
                >
                <span
                  style="
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: #6c707d;
                    border-radius: 100px;
                    margin: 0 10px;
                  "
                ></span>
                {{audienceOverallPerformanceData.uniqueOpened || 0 }} Total
                Opens
              </p>
              <ProgressBar
                :class="'color-1'"
                :value="percentageCalc(audienceOverallPerformanceData.totalOpened,audienceOverallPerformanceData.totalDelivered)"
              />
            </div>
          </div>
          <div class="premium-analytics-card bg-white radius-10 d-flex">
            <div class="content w-100">
              <p class="analytics-status text-color-5 font-15 font-w-500 mb-1">
                Click Rate
              </p>
              <p
                class="info d-flex align-items-center font-14 line-h-16 font-w-500 mb-0 text-color-2"
              >
                <b class="font-16 l-height-24 font-w-600 base-color"
                  >{{percentageCalc(audienceOverallPerformanceData.uniqueClicked,audienceOverallPerformanceData.uniqueDelivered)
                  || 0}}%</b
                >
                <span
                  style="
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: #6c707d;
                    border-radius: 100px;
                    margin: 0 10px;
                  "
                ></span>
                {{audienceOverallPerformanceData.uniqueClicked || 0}} Total
                Clicks
              </p>
              <ProgressBar
                :class="'color-2'"
                :value="percentageCalc(audienceOverallPerformanceData.totalClicked,audienceOverallPerformanceData.totalDelivered)"
              />
            </div>
          </div>
          <div class="premium-analytics-card bg-white radius-10 d-flex">
            <div class="content w-100">
              <p class="analytics-status text-color-5 font-15 font-w-500 mb-1">
                Conversion Rate
              </p>
              <p
                class="info d-flex align-items-center font-14 line-h-16 font-w-500 mb-0 text-color-2"
              >
                <b class="font-16 l-height-24 font-w-600 base-color"
                  >{{percentageCalc(audienceOverallPerformanceData.totalConversion,audienceOverallPerformanceData.uniqueClicked)
                  || 0}}%</b
                >
                <span
                  style="
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: #6c707d;
                    border-radius: 100px;
                    margin: 0 10px;
                  "
                ></span>
                {{audienceOverallPerformanceData.totalConversion || 0}} Total
                Conversions
              </p>
              <ProgressBar
                :class="'color-3'"
                :value="percentageCalc(audienceOverallPerformanceData.totalConversion,audienceOverallPerformanceData.totalClicked)"
              />
            </div>
          </div>
          <div class="premium-analytics-card bg-white radius-10 d-flex">
            <div class="content w-100">
              <p class="analytics-status text-color-5 font-15 font-w-500 mb-1">
                Total Conversion
              </p>
              <p
                class="info d-flex align-items-center font-14 line-h-16 font-w-500 mb-0 text-color-2"
              >
                <b class="font-16 l-height-24 font-w-600 base-color"
                  >{{audienceOverallPerformanceData.totalConversionValue || 0}}
                </b>
                <span
                  style="
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: #6c707d;
                    border-radius: 100px;
                    margin: 0 10px;
                  "
                ></span>
                {{audienceOverallPerformanceData.uniqueDelivered || 0}} Total
                Deliveries
              </p>
              <ProgressBar
                :class="'color-4'"
                :value="percentageCalc(audienceOverallPerformanceData.totalConversion,audienceOverallPerformanceData.uniqueClicked)"
              />
            </div>
          </div>
          <div class="premium-analytics-card bg-white radius-10 d-flex">
            <div class="content w-100">
              <p class="analytics-status text-color-5 font-15 font-w-500 mb-1">
                Unsubscribe Rate
              </p>
              <p
                class="info d-flex align-items-center font-14 line-h-16 font-w-500 mb-0 text-color-2"
              >
                <b class="font-16 l-height-24 font-w-600 base-color"
                  >{{percentageCalc(audienceOverallPerformanceData.totalUnsubscriber,audienceOverallPerformanceData.totalMember)
                  }}%
                </b>
                <span
                  style="
                    display: inline-block;
                    height: 4px;
                    width: 4px;
                    background: #6c707d;
                    border-radius: 100px;
                    margin: 0 10px;
                  "
                ></span>
                {{audienceOverallPerformanceData.totalMember || 0}} Total
                Audience
              </p>
              <ProgressBar
                :class="'color-4'"
                :value="percentageCalc(audienceOverallPerformanceData.totalUnsubscriber,audienceOverallPerformanceData.totalMember)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="audienceVariationsData?.length != 0"
        class="advanced-reports-box radius-10 py-20 px-24"
      >
        <div
          class="overall-performance-card-header d-flex justify-content-between align-items-center mb-10"
        >
          <div class="left">
            <p class="mb-0 font-20 l-height-25 font-w-400">Audience</p>
          </div>
          <div class="right d-flex align-items-center">
            <div class="d-flex align-items-center">
              <label for="" class="mb-0 pr-8 font-13 font-w-500"
                >Date Range</label
              >
              <AppDateRangePicker
                :dateRangeProps="[this.startDate,this.endDate]"
                @dateSelected="handleDateSelected"
                @error="handleError"
                :disableFutureDates="true"
                :class="''"
                :style="{ width: '210px' }"
              >
              </AppDateRangePicker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="">
              <div class="">
                <div class="content w-100">
                  <div class="mb-23">
                    <div class="info d-flex align-items-center">
                      <span>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.6673 28V25.3333C22.6673 23.9188 22.1054 22.5623 21.1052 21.5621C20.105 20.5619 18.7485 20 17.334 20H6.66732C5.25283 20 3.89628 20.5619 2.89608 21.5621C1.89589 22.5623 1.33398 23.9188 1.33398 25.3333V28M30.6673 28V25.3333C30.6664 24.1516 30.2731 23.0037 29.5491 22.0698C28.8252 21.1358 27.8115 20.4688 26.6673 20.1733M21.334 4.17333C22.4812 4.46707 23.498 5.13427 24.2242 6.06975C24.9503 7.00523 25.3444 8.15577 25.3444 9.34C25.3444 10.5242 24.9503 11.6748 24.2242 12.6103C23.498 13.5457 22.4812 14.2129 21.334 14.5067M17.334 9.33333C17.334 12.2789 14.9462 14.6667 12.0007 14.6667C9.05513 14.6667 6.66732 12.2789 6.66732 9.33333C6.66732 6.38781 9.05513 4 12.0007 4C14.9462 4 17.334 6.38781 17.334 9.33333Z"
                            stroke="#0052CC"
                            stroke-width="2.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <h2 class="text-color mb-0 font-35 l-height-42 ml-10">
                        {{audienceVariationsData?.totalAudience || 0}}
                      </h2>
                    </div>
                    <h3
                      class="link-activity-status text-color-2 font-14 l-height-24 font-w-500 mb-0"
                    >
                      Total Audience
                    </h3>
                  </div>
                  <div class="d-flex flex-wrap audience-info">
                    <div
                      class="audience-info-card pt-10 pb-25 border-bottom-shadow-1 border-right-shadow-1"
                    >
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{audienceVariationsData?.signupFormTotal || 0}}
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          Signup Form
                        </h3>
                      </div>
                    </div>
                    <div
                      class="audience-info-card pt-10 pb-25 border-bottom-shadow-1 pl-59"
                    >
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{audienceVariationsData?.totalWebcommanderSource ||
                          0}}
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          WebCommander
                        </h3>
                      </div>
                    </div>
                    <div
                      class="audience-info-card pt-10 pb-25 border-bottom-shadow-1 border-right-shadow-1"
                    >
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{audienceVariationsData?.totalSubscriber || 0}}
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          Subscribers
                        </h3>
                      </div>
                    </div>
                    <div
                      class="audience-info-card pt-10 pb-25 border-bottom-shadow-1 pl-59"
                    >
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{audienceVariationsData?.totalUnsubscriber || 0}}
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          Unsubscribers
                        </h3>
                      </div>
                    </div>
                    <div
                      class="audience-info-card pt-10 pb-25 border-right-shadow-1"
                    >
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{audienceVariationsData?.changeInAudience || 0}}
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          Change in Audience
                        </h3>
                      </div>
                    </div>
                    <div class="audience-info-card pt-10 pb-25-1 pl-59">
                      <div class="info">
                        <h2 class="mb-0 font-20 l-height-27 font-w-500">
                          {{growthRate(audienceVariationsData?.beforeTotalAudience,audienceVariationsData?.afterTotalAudience)
                          }}%
                        </h2>
                        <h3
                          class="link-activity-status font-14 l-height-24 text-color-2 mb-0"
                        >
                          Growth Rate
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <AppAreaChart
              v-if="audienceVariationsData?.audienceLineGraph?.length"
              :lineGraphData="audienceVariationsData?.audienceLineGraph"
            >
            </AppAreaChart>
          </div>
        </div>
      </div>
      <div class="advanced-reports-box radius-10 py-31 px-24">
        <div class="mb-16 d-flex flex-wrap align-items-center gap-3">
          <p class="mb-0 font-21 l-height-25 font-w-400">
            Performance Benchmarks
          </p>
          <div class="buttons-tab-menu d-flex">
            <kbutton
              :theme-color="item?.checked ? 'primary' : 'base'"
              :key="index"
              @click="toggleFilterDeviceHandler('performanceBenchmarks', index)"
              v-for="(item,index) in deviceFilterObject?.performanceBenchmarks"
              >{{item?.name}}
            </kbutton>
          </div>
        </div>
        <div v-if="audiencePerformanceBenchmarkData?.length != 0">
          <div class="d-flex performance-benchmarks-row">
            <div class="left-45">
              <div class="clients-and-devices-chart mb-24">
                <Chart>
                  <ChartTooltip :format="'{0}%'" />
                  <ChartLegend
                    :position="'bottom'"
                    :orientation="'horizontal'"
                  />
                  <ChartSeries>
                    <ChartSeriesItem
                      v-for="(item, index) in audiencePerformanceBenchmarkData"
                      :key="index"
                      :name="item?.name"
                      :type="'column'"
                      :data-items="item?.dataValue"
                    />
                  </ChartSeries>
                </Chart>
              </div>
            </div>
            <div class="right-55 pl-46">
              <div>
                <p
                  class="last-5-campaigns mb-11 font-16 l-height-22 font-w-500 border-0 base-color"
                >
                  Last 5 Campaigns
                </p>
                <Grid
                  :class="'performance-benchmark-table table-design-2 pager-fixed-with-table'"
                  ref="grid"
                  :data-items="audiencePerformanceBenchmarkData"
                  :columns="columnForPerformanceBenchmark"
                >
                  <GridNoRecords> No Data found. </GridNoRecords>
                  <template v-slot:clickRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.clickRate}}%</span
                        >
                      </div>
                    </td>
                  </template>
                  <template v-slot:openRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.openRate}}%</span
                        >
                      </div>
                    </td>
                  </template>
                  <template v-slot:bounceRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.bouncedRate}}%</span
                        >
                      </div>
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="performanceByLocation"></component>
            <p
              class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24"
            >
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
      <div class="advanced-reports-box radius-10 py-34 px-24">
        <p class="mb-26 font-21 l-height-25 font-w-400">
          Geographic Distribution
        </p>
        <div v-if="audiencePerformanceByLocationData?.length != 0">
          <div class="d-flex performance-by-locations-row">
            <div class="left-50">
              <div>
                <Grid
                  :class="'performance-by-locations-table table-design-2 pager-fixed-with-table max-w-740'"
                  ref="grid"
                  :data-items="audiencePerformanceByLocationData"
                  :columns="columnForPerformanceByLocations"
                >
                  <GridNoRecords> No Data found. </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.category}}</span
                        >
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} Recipients)
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
            <div class="right-40 pl-46">
              <div
                class="d-flex flex-column align-items-start clients-and-devices-chart mb-24"
              >
                <Chart class="w-100 text-left">
                  <ChartTooltip :render="'renderTooltip'">
                    <template v-slot:renderTooltip="{ props }">
                      <div v-if="props">
                        {{ props?.category }} {{ props.point.category }}
                        ({{props.point.value.toFixed(2)}}%)
                      </div>
                    </template>
                  </ChartTooltip>
                  <ChartTitle :position="'bottom'" :text="''" />
                  <ChartLegend :position="'bottom'" />
                  <ChartSeries>
                    <ChartSeriesItem
                      :type="'pie'"
                      :data-items="audiencePerformanceByLocationData"
                      :field="'value'"
                      :category-field="'category'"
                    />
                  </ChartSeries>
                </Chart>
                <p
                  class="text-center text-color font-12 l-height-14 max-w-244 mb-0 mx-auto"
                >
                  Displaying distribution of locations in a pie chart.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="performanceByLocation"></component>
            <p
              class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24"
            >
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
      <div v-if="!this.segmentId" class="advanced-reports-box py-28 px-24">
        <p class="mb-23 font-21 l-height-25 font-w-400">Subscriber Sources</p>
        <div v-if="audiencePrimarySourceData?.length != 0">
          <Grid
            :class="'subscriber-sources-table table-design-2 pager-fixed-with-table'"
            ref="grid"
            :data-items="audiencePrimarySourceData"
            :columns="subscriberSourceColumns"
          >
            <GridNoRecords>
              Not enough data at this time. Please try again later.
            </GridNoRecords>
          </Grid>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="performanceByLocation"></component>
            <p
              class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24"
            >
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
      <div class="advanced-reports-box radius-10 py-28 px-24">
        <div class="mb-16 d-flex align-items-center gap-3">
          <p class="mb-0 font-21 l-height-25 font-w-400">
            Breakdown by Device Type
          </p>
        </div>
        <div
          v-if="audienceClientDevicesData?.mobile?.length != 0 || audienceClientDevicesData?.desktop?.length != 0"
        >
          <div class="d-flex clients-devices-row">
            <div class="left-40">
              <div
                class="d-flex flex-column align-items-start clients-and-devices-chart mb-24"
              >
              <div class="buttons-tab-menu d-flex">
                <kbutton
                  :theme-color="item?.checked ? 'primary' : 'base'"
                  @click="toggleFilterDeviceHandler('clientAndDevices', index)"
                  v-for="(item,index) in deviceFilterObject?.clientAndDevices"
                  >{{item?.name}}
                </kbutton>
              </div>
                <Chart v-if="audienceClientDevicesData?.selected?.length != 0" class="w-100 text-center">
                  <ChartTooltip :render="'renderTooltip'">
                    <template v-slot:renderTooltip="{ props }">
                      <div v-if="props">
                        {{ props?.category }} {{ props.point.category }} ({{
                        props.point.value}}%)
                      </div>
                    </template>
                  </ChartTooltip>
                  <ChartTitle position="bottom" :text="''" />
                  <ChartLegend :position="'bottom'" />
                  <ChartSeries>
                    <ChartSeriesItem
                      :type="'pie'"
                      :data-items="audienceClientDevicesData?.selected"
                      :field="'value'"
                      :category-field="'category'"
                    />
                  </ChartSeries>
                </Chart>
                <div v-else>
                  <div class="ps-3 mb-4">
                    <component is="clientsAndDevices" class="h-340"></component>
                    <p
                      class="max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-14 l-height-24"
                    >
                      No analytics were generated for this section yet.
                    </p>
                  </div>
                </div>
                <p
                  class="text-center text-color font-12 l-height-14 max-w-244 mb-0 mx-auto"
                  v-if="audienceClientDevicesData?.selected?.length != 0"
                >
                  Showing distribution of email clients and devices in a pie
                  chart
                </p>
              </div>
            </div>
            <div class="right-60 pl-46">
              <div>
                <Grid
                  :class="'clients-and-devices-table table-design-2 pager-fixed-with-table max-w-600'"
                  ref="grid"
                  :data-items="audienceClientDevicesData?.mobile"
                  :columns="columnForClientAndDevicesTypeMobile"
                >
                  <GridNoRecords>
                    Not enough data at this time. Please try again later.
                  </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.category}}</span
                        >
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} Recipients)
                    </td>
                  </template>
                </Grid>
              </div>
              <br />
              <br />
              <div>
                <Grid
                  :class="'clients-and-devices-table table-design-2 pager-fixed-with-table max-w-600'"
                  ref="grid"
                  :data-items="audienceClientDevicesData?.desktop"
                  :columns="columnForClientAndDevicesTypeDesktop"
                >
                  <GridNoRecords>
                    Not enough data at this time. Please try again later.
                  </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1"
                          >{{props.dataItem?.category}}</span
                        >
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} Recipients)
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="clientsAndDevices"></component>
            <p
              class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24"
            >
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="this.iscampaignListVisible"
        class="advanced-reports-box radius-10 py-28 px-24 position-relative"
      >
        <p class="mb-17 font-21 l-height-25 font-w-400">Campaigns</p>
        <ListAndSegmentAllCampaign
          @campignListVisibilityHandler="campignListVisibilityHandler"
        />
      </div>
    </div>
  </div>
</div>
