<template>
  <Chart
    :zoomable="{
      mousewheel: {
        lock: 'y',
      },
      selection: {
        lock: 'y',
      },
    }"
    :pannable="{
      lock: 'y',
    }"
  >
    <ChartLegend :position="'top'" :orientation="'horizontal'" />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem
        :title="{ text: '' }"
        :labels="labelFormat"
        :categories="dataAnalytics.categories"
      />
    </ChartCategoryAxis>
    <ChartSeries>
      <ChartSeriesItem
        v-for="(item, index) in series"
        :key="index"
        :data-items="item.items"
        :name="item?.items[0]?.title"
        :field="'yAxis'"
        :type="'line'"
        :color="item?.items[0]?.color"
      >
        <ChartSeriesItemTooltip :render="'nestedTooltipRender'">
          <template v-slot:nestedTooltipRender="{ props }">
            <div
              class="w-220 p-3"
              :mouseover="tooltipFunc(item.items, props.point)"
            >
              <div class="font-16">
                {{ categoryPointToolTip }}
              </div>
              <div class="mt-22 font-12 text-white d-flex">
                Sent
                <b class="ms-auto text-white"
                  >{{ totalSentToolTip }} recipients</b
                >
              </div>
              <div class="mt-22 font-12 text-white d-flex">
                Bounce
                <b class="ms-auto text-white"
                  >{{ totalBouncedToolTip }} bounce</b
                >
              </div>
              <div class="mt-22 font-12 text-white d-flex">
                Click Rate
                <b class="ms-auto text-white"
                  >{{
                    percentageCalc(
                      totalClickedToolTip,
                      dataAnalytics?.totalDelivered
                    )
                  }}% ({{ totalClickedToolTip }} click)</b
                >
              </div>
              <div class="mt-22 font-12 text-white d-flex">
                Open Rate
                <b class="ms-auto text-white"
                  >{{
                    percentageCalc(
                      totalOpenedToolTip,
                      dataAnalytics?.totalDelivered
                    )
                  }}% ({{ totalOpenedToolTip }} opens)</b
                >
              </div>
              <div class="mt-22 font-12 text-white d-flex">
                Conversion
                <b class="ms-auto text-white">{{ convValueToolTip }}</b>
              </div>
              <!-- <div class="mt-22 font-12 text-white d-flex">
                Conversion Value <b class="ms-auto text-white">$232.32</b>
              </div> -->
            </div>
          </template>
        </ChartSeriesItemTooltip>
      </ChartSeriesItem>
    </ChartSeries>
  </Chart>
</template>
<script>
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartSeriesItemTooltip,
  ChartLegend,
} from "@progress/kendo-vue-charts";
import { groupBy } from "@progress/kendo-data-query";
import "hammerjs";
import { throttleFunction } from "@/utils/helper";

export default {
  components: {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    ChartSeriesItemTooltip,
    ChartLegend,
  },
  props: {
    dataAnalytics: {
      type: Object,
      required: true,
    },
    loader: {
      type: Boolean,
      required: true,
    },
    legendVisibility: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.tooltipFunc = throttleFunction(this.tooltipFuncHandler, 500);
  },
  mounted() {
    if (!this.loader && this.dataAnalytics?.campaignOverviewLineGraphList) {
      this.tooltipObjectCreationHandler();
      this.series = groupBy(this.dataAnalytics?.campaignOverviewLineGraphList, [
        { field: "title" },
      ]);
    }
  },
  watch: {
    dataAnalytics: {
      handler: function (val) {
        if (val?.campaignOverviewLineGraphList) {
          this.tooltipObjectCreationHandler();
          this.series = groupBy(
            this.dataAnalytics?.campaignOverviewLineGraphList,
            [{ field: "title" }]
          );
        }
      },
      deep: true,
    },
  },
  data: function () {
    return {
      series: [],
      totalSentToolTip: 0,
      totalBouncedToolTip: 0,
      totalClickedToolTip: 0,
      totalOpenedToolTip: 0,
      convValueToolTip: 0,

      totalDeliveredToolTip: 0,
      categoryPointToolTip: "",
      clickRateClick: 0,
      openRateOpen: 0,
      tooltipData: {},
      labelFormat: {
        format: "d",
        rotation: "auto",
      },
    };
  },
  methods: {
    tooltipObjectCreationHandler() {
      this.dataAnalytics.categories.forEach((item) => {
        this.dataAnalytics.campaignOverviewLineGraphList.forEach((item1) => {
          if (item == item1.xAxis && item1.title == "Sent") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalSentToolTip: item1.yAxis,
              },
            };
          }
          if (item == item1.xAxis && item1.title == "Bounced") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalBouncedToolTip: item1.yAxis,
              },
            };
          }
          if (item == item1.xAxis && item1.title == "Clicked") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalClickedToolTip: item1.yAxis,
              },
            };
          }
          if (item == item1.xAxis && item1.title == "Opened") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalOpenedToolTip: item1.yAxis,
              },
            };
          }
          if (item == item1.xAxis && item1.title == "Delivered") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalDeliveredToolTip: item1.yAxis,
              },
            };
          }
          if (item == item1.xAxis && item1.title == "Conversions") {
            this.tooltipData = {
              ...this.tooltipData,
              [item]: {
                ...this.tooltipData[item],
                totalConversionValue: item1.yAxis,
              },
            };
          }
        });
      });
    },
    tooltipFuncHandler(eItem, point) {
      this.totalSentToolTip =
        this.tooltipData[point.category]?.totalSentToolTip || 0;

      this.totalBouncedToolTip =
        this.tooltipData[point.category]?.totalBouncedToolTip || 0;

      this.totalClickedToolTip =
        this.tooltipData[point.category]?.totalClickedToolTip || 0;

      this.totalOpenedToolTip =
        this.tooltipData[point.category]?.totalOpenedToolTip || 0;

      this.convValueToolTip =
        this.tooltipData[point.category]?.totalConversionValue || 0;

      this.totalDeliveredToolTip =
        this.tooltipData[point.category]?.totalDeliveredToolTip || 0;

      this.categoryPointToolTip = point.category;
    },
    percentageCalc(value, total) {
      if (!value || !total) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return Math.floor((value / total) * 100);
        } else {
          return 0;
        }
      }
    },
  },
};
</script>
